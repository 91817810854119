<template>
  <TriggerModal />
  <StepModal />
  <Overlay />
  <Images />

  <div>
    <h5 class="text-center">{{ currentTemplate.name }}</h5>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('templates.properties')" active>
          <b-table-simple
            small
            v-if="currentTemplateData.id"
            style="max-width: 500px"
          >
            <b-tbody>
              <b-tr>
                <b-td style="width: 200px; font-weight: 600; text-align: right"
                  >{{ $t("templates.template_number") }}:</b-td
                >
                <b-td class="pl-2">
                  {{ currentTemplateData.number }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="width: 200px; font-weight: 600; text-align: right"
                  >{{ $t("templates.version") }}:</b-td
                >
                <b-td class="pl-2">
                  {{ currentTemplateData.version }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="width: 200px; font-weight: 600; text-align: right"
                  >{{ $t("templates.active") }}:</b-td
                >
                <b-td class="pl-2">
                  <font-awesome-icon
                    :style="{
                      color: currentTemplateData.active ? 'green' : 'grey',
                    }"
                    icon="check"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="width: 200px; font-weight: 600; text-align: right">
                  {{ $t("templates.author") }}:
                </b-td>
                <b-td class="pl-2">
                  <span
                    >{{ currentTemplateData.creator.last_name }}
                    {{ currentTemplateData.creator.first_name }}</span
                  >
                  <span class="ml-3">{{
                    moment(currentTemplateData.created_at).format(
                      "DD.MM.YYYY HH:mm"
                    )
                  }}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="width: 200px; font-weight: 600; text-align: right">
                  {{ $t("templates.last_changed") }}:
                </b-td>
                <b-td class="pl-2">
                  <template v-if="currentTemplateData.updator">
                    <span
                      >{{ currentTemplateData.updator.last_name }}
                      {{ currentTemplateData.updator.first_name }}</span
                    >
                    <span class="ml-3">{{
                      moment(currentTemplateData.updated_at).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    }}</span>
                  </template>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <b-tab :title="$t('templates.triggers')">
          <button
            :title="$t('templates.add_new_item')"
            class="btn btn-sm btn-outline-info mb-2 float-right"
            type="button"
            @click="addNewTrigger"
          >
            <font-awesome-icon icon="plus" />
          </button>

          <template v-if="currentTemplateData.triggers">
            <Trigger
              v-for="(trigger, i) in currentTemplateData.triggers"
              :trigger="trigger"
              :key="i"
            />
          </template>
        </b-tab>
        <b-tab :title="$t('templates.steps')">
          <button
            v-if="
              !currentTemplateData ||
              !currentTemplateData.steps ||
              !currentTemplateData.steps.length
            "
            :title="$t('steps.add_new_item')"
            class="btn btn-sm btn-outline-info mb-2 float-right"
            type="button"
            @click="addNewStep"
          >
            <font-awesome-icon icon="plus" />
          </button>

          <!-- <div>
            <button
              style="position: absolute; right: 10px; top: 108px"
              class="btn btn-sm btn-outline-secondary"
              type="button"
              @click="toglleSteps"
            >
              <font-awesome-icon
                :icon="!showSteps ? 'chevron-down' : 'chevron-up'"
              />
            </button>
          </div> -->

          <div
            class="clinicsContainer accordion"
            v-if="currentTemplateData.steps"
          >
            <draggable
              v-model="currentTemplateData.steps"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              @change="sortItems"
            >
              <template #item="{ element }">
                <Step
                  :step="element"
                  :key="i"
                  :stepKey="stepKey"
                  @handleImageClick="handleImageClick"
                />
              </template>
            </draggable>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";
  import moment from "moment";
  import Trigger from "../triggers/Trigger.vue";
  import TriggerModal from "../triggers/TriggerModal.vue";
  import StepModal from "../steps/StepModal.vue";
  import Step from "../steps/Step.vue";
  import draggable from "vuedraggable";
  import Overlay from "../Overlay.vue";
  import Images from "../steps/Images.vue";
  import $ from "jquery";

  export default {
    data() {
      return {
        height: window.innerHeight,
        scrollHeight: window.innerHeight - 160,
        showSteps: false,
      };
    },
    props: [],
    computed: {
      ...mapGetters({
        currentTemplate: "currentTemplate",
        currentTemplateId: "currentTemplateId",
        currentTemplateData: "currentTemplateData",
        stepKey: "stepKey",
      }),
    },
    methods: {
      handleImageClick(event) {
        console.log(event);
      },
      sortItems({ moved: { element, oldIndex, newIndex } }) {
        console.log("elem", element);
        this.$store.dispatch("updateStepPosition", {
          id: element.id,
          oldIndex,
          newIndex,
        });
      },
      toglleSteps() {
        if (this.showSteps) {
          $(".accordion .collapse").removeClass("show");
        } else {
          $(".accordion .collapse").addClass("show");
        }
        this.showSteps = !this.showSteps;
      },
      moment(v) {
        return moment(v);
      },
      addNewTrigger() {
        this.$store.commit(types.SET_CURRENT_TRIGGER, {});
        this.$store.commit(types.SET_TRIGGER_MODAL, true);
      },
      addNewStep() {
        this.$store.commit(types.SET_CURRENT_STEP, {});
        this.$store.dispatch("getMaxStepsPosition");
        this.$store.dispatch("getMaxStepsNumber");
        this.$store.commit(types.SET_STEP_MODAL, true);
      },
    },
    components: {
      Trigger,
      TriggerModal,
      Step,
      StepModal,
      draggable,
      Overlay,
      Images,
    },
  };
</script>