<template>
  <div
    @click="showTemplateData"
    :class="[activeTemplate, 'mb-1 on_hover card']"
  >
    <div class="card-header d-flex justify-content-between align-items-center">
      <div>
        <span :class="!template.active && 'text-decoration-line-through'">
          {{ template.name }}
        </span>
      </div>
      <button
        type="button"
        class="btn btn-light dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click.prevent=""
      >
        <font-awesome-icon icon="cog" />
      </button>
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item" href="#" @click="updateTemplate">{{
            $t("all.edit")
          }}</a>
        </li>
        <li>
          <a class="dropdown-item text-danger" @click="deleteDialog" href="#">{{
            $t("all.delete")
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";

  export default {
    data() {
      return {};
    },
    props: ["template"],
    computed: {
      ...mapGetters({
        currentTemplate: "currentTemplate",
        currentTemplateId: "currentTemplateId",
      }),
      activeTemplate() {
        return this.template.id == this.currentTemplateId ? "border-success" : null;
      },
    },
    methods: {
      deleteDialog() {
        const store = this.$store;
        const id = this.currentTemplate.id;

        this.$confirm.require({
          message: "Бажаєте видалити?",
          header: "Підтвердження",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Так",
          rejectLabel: "Ні",
          accept: () => {
            store.commit(types.SET_OVERLAY_VISIBLE, true);
            store.dispatch("deleteTemplate", id);
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
          onHide: () => {
            //Callback to execute when dialog is hidden
          },
        });
      },
      showTemplateData() {
        this.$store.commit(types.SET_OVERLAY_VISIBLE, true);
        this.$store.commit(types.SET_CURRENT_TEMPLATE, this.template);

        this.$store.dispatch("getCurrentTemplateData", this.template.id);
      },
      updateTemplate() {
        this.$store.commit(types.SET_CURRENT_TEMPLATE, this.template);
        this.$store.commit(types.SET_TEMPLATE_MODAL, true);
      },
    },
    components: {},
  };
</script>