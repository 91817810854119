<template>
  <Overlay />

  <div class="card" v-if="stepData.id">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="flex-1">
        <span class="font-weight-bold d-block">
          {{ stepData.number }}
        </span>
        <span>
          {{ stepData.name }}
        </span>
      </div>
    </div>
    <div class="card-body">
      <div v-html="stepData.description" />
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";
  import Overlay from "../Overlay.vue";

  export default {
    data() {
      return {};
    },
    props: [],
    computed: {
      ...mapGetters({
        stepData: "stepData",
        prevStepLink: "prevStepLink",
        nextStepLink: "nextStepLink",
      }),
    },
    methods: {},
    components: { Overlay },
    created() {
      let pathArray = window.location.pathname.split("/");

      let link = pathArray.length == 3 ? pathArray.pop() : false;

      if (!link) {
        this.link = false;
      }

      this.$store.commit(types.SET_OVERLAY_VISIBLE, true);
      this.$store.dispatch("getStepData", link);
    },
  };
</script>