<template>
  <div
    class="d-flex flex-direction-row justify-content-start align-items-center"
  >
    <button
      class="btn btn-sm btn-outline-info mr-2"
      type="button"
      @click="addNewStep"
    >
      <font-awesome-icon icon="plus" />
    </button>
    <div :class="[activeStep, 'mb-1 on_hover card flex-1']">
      <div
        class="card-header d-flex justify-content-between align-items-center pb-1 pt-1"
      >
        <div
          data-bs-toggle="collapse"
          :data-bs-target="`#collapseOne${step.id}`"
          class="flex-1"
          @click="stepClicked"
        >
          <span class="font-weight-bold d-block">
            <span class="d-inline-block mr-2">{{ step.position }}</span>
            {{ step.name }}
          </span>
        </div>
        <a
          :href="`/step/${step.link}`"
          style="float: right"
          target="_new"
          class="p-1"
          ><font-awesome-icon icon="link"
        /></a>
        <button
          type="button"
          class="btn btn-light dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          @click.prevent=""
        >
          <font-awesome-icon icon="cog" />
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" @click="updateStep">{{
              $t("all.edit")
            }}</a>
          </li>
          <li>
            <a
              class="dropdown-item text-danger"
              @click="deleteDialog"
              href="#"
              >{{ $t("all.delete") }}</a
            >
          </li>
        </ul>
      </div>
      <b-collapse
        :id="`collapseOne${step.id}`"
        class="accordion-collapse collapse card-body"
        :aria-labelledby="`headingOne${step.id}`"
      >
        <div v-html="step.description" />
      </b-collapse>
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";
  import $ from "jquery";

  export default {
    data() {
      return {};
    },
    props: ["step", "stepKey"],
    computed: {
      ...mapGetters({
        currentStep: "currentStep",
        currentStepId: "currentStepId",
      }),
      activeStep() {
        return this.step.id == this.currentStepId ? "border-success" : null;
      },
    },
    methods: {
      addNewStep() {
        console.log(
          "parseInt(this.step.position) + 1",
          parseInt(this.step.position) + 1
        );

        setTimeout(() => {
          this.$store.commit(
            types.SET_MAX_STEPS_POSITION,
            parseInt(this.step.position) + 1
          );
          this.$store.commit(
            types.SET_MAX_STEPS_NUMBER,
            parseInt(this.step.position) + 1
          );
        }, 500);

        this.$store.commit(types.SET_CURRENT_STEP, {});
        this.$store.commit(types.SET_STEP_MODAL, true);
      },
      listenDescriptionClick() {
        const store = this.$store;
        $("img").on("click", function (e) {
          console.log("IMAGE CLICKED");
          let initialSrs = $(e.target).prop("src");
          initialSrs = initialSrs.replace("https://qa.docdream.com/", "");
          initialSrs = initialSrs.replace("http://qa.docdream.com/", "");
          let index = 0;
          const sources = [];

          let images = $(this).closest("div.accordion-collapse").find("img");

          images.each((el, i) => {
            let curSource = $(i).attr("src");
            curSource = curSource.replace("https://qa.docdream.com/", "");
            curSource = curSource.replace("http://qa.docdream.com/", "");

            if (curSource == initialSrs) {
              index = el;
            }

            sources.push(
              "https://qa.docdream.com/" +
                curSource.replace("thumbnails", "images")
            );
          });

          store.commit(types.SET_CAROUSEL_IMAGES, sources);
          store.commit(types.SET_IMAGES_INDEX, index);
          store.commit(types.SET_IMAGES_MODAL, true);
        });
      },

      stepClicked() {
        if (!this.step.description) {
          console.log("NO DESC");
          this.$store.commit(types.SET_OVERLAY_VISIBLE, true);
          this.$store.dispatch("getStepDescription", { id: this.step.id });
        } else {
          console.log("HAS DESC");
          this.listenDescriptionClick();
        }
      },
      deleteDialog() {
        const store = this.$store;
        const id = this.step.id;
        this.$confirm.require({
          message: "Бажаєте видалити?",
          header: "Підтвердження",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Так",
          rejectLabel: "Ні",
          accept: () => {
            store.commit(types.SET_OVERLAY_VISIBLE, true);
            store.dispatch("deleteStep", id);
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
          onHide: () => {
            //Callback to execute when dialog is hidden
          },
        });
      },
      updateStep() {
        if (this.step.description) {
          this.$store.commit(types.SET_CURRENT_STEP, this.step);
          this.$store.commit(types.SET_STEP_MODAL, true);
        } else {
          this.$store.commit(types.SET_CURRENT_STEP, this.step);
          this.$store.dispatch("getStepDescription", {
            id: this.step.id,
            show: true,
          });
        }
      },
    },
    components: {},
    mounted() {},
    updated() {
      this.listenDescriptionClick();
    },
  };
</script>