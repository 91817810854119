import * as types from "../types";

const state = {
    currentStep: {},
    currentStepId: false,
    stepModalVisible: false,
    maxStepsPosition: 0,
    maxStepsNumber: 0,
    stepData: {},
    prevStepLink: false,
    nextStepLink: false,
    images: [],
    imagesModalVisible: false,
    imagesIndex:0
};

const mutations = {
    [types.SET_STEP_MODAL]: (state, status) => {
        state.stepModalVisible = status;
    },
    [types.SET_MAX_STEPS_POSITION]: (state, position) => {
        state.maxStepsPosition = position;
    },
    [types.SET_MAX_STEPS_NUMBER]: (state, number) => {
        state.maxStepsNumber = number;
    },
    [types.SET_STEP_DATA]: (state, data) => {
        state.stepData = data.data;
        state.prevStepLink = data.prev;
        state.nextStepLink = data.next;
    },
    [types.SET_CURRENT_STEP]: (state, data) => {
        state.currentStep = data;
        state.currentStepId = data.id;
    },
    [types.SET_CAROUSEL_IMAGES]: (state, data) => {
        state.images = data;
    },
    [types.SET_IMAGES_MODAL]: (state, status) => {
        state.imagesModalVisible = status;
    },
    [types.SET_IMAGES_INDEX]: (state, index) => {
        state.imagesIndex = index;
    },
};

const getters = {
    imagesModalVisible(state) {
        return state.imagesModalVisible;
    },
    images(state) {
        return state.images;
    },
    currentStep(state) {
        return state.currentStep;
    },
    currentStepId(state) {
        return state.currentStepId;
    },
    stepModalVisible(state) {
        return state.stepModalVisible;
    },
    maxStepsPosition(state) {
        return state.maxStepsPosition;
    },
    maxStepsNumber(state) {
        return state.maxStepsNumber;
    },
    stepData(state) {
        return state.stepData;
    },
    prevStepLink(state) {
        return state.prevStepLink;
    },
    nextStepLink(state) {
        return state.nextStepLink;
    },
    imagesIndex(state) {
        return state.imagesIndex;
    },
};

export default {
    state,
    mutations,
    getters,
};
