<template>
  <Modal
    v-model="showModal"
    :close="closeModal"
    @before-enter="fillData"
    @after-enter="focus"
  >
    <div
      class="smModal"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <a href="#" class="closeModalButton" @click.prevent="closeModal">
            <font-awesome-icon icon="times" />
          </a>
          <p class="h5">{{ $t("triggers.trigger") }}</p>

          <div class="d-block">
            <div class="mb-2 form-group" :label="$t('triggers.code')">
              <label for="code" class="form-label text-start d-block">
                {{ $t("triggers.code") }}
              </label>
              <input
                id="code"
                autofocus
                autocapitalize="sentences"
                class="form-control"
                v-model="trigger.code"
                type="text"
                ref="code"
                @keyup.enter="handleAction"
              />
            </div>

            <div class="mb-2 form-group" :label="$t('triggers.trigger')">
              <label for="name" class="form-label text-start d-block">
                {{ $t("triggers.trigger") }}
              </label>
              <input
                id="name"
                autofocus
                autocapitalize="sentences"
                class="form-control"
                v-model="trigger.name"
                type="text"
                ref="name"
                @keyup.enter="handleAction"
              />
            </div>
          </div>
        
          <button
            :disabled="!(trigger.name && trigger.code)"
            class="btn btn-outline-success mt-3"
            @click="handleAction"
          >
            {{ $t(trigger.id ? "all.edit" : "all.add") }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";

  export default {
    data() {
      return {
        trigger: {
          id: false,
          name: "",
          code: "",
        },
      };
    },
    methods: {
      handleAction() {
        if (this.trigger.name && this.trigger.code) {
          this.$store.commit(types.SET_OVERLAY_VISIBLE, true);
          this.$store.dispatch("handleTrigger", {...this.trigger, template_id: this.currentTemplateId});
          this.closeModal();
        }
      },
      
      closeModal() {
        this.$store.commit(types.SET_TRIGGER_MODAL, false);
        this.resetModal();
      },
      focus() {
        if (this.$refs.trigger) {
          this.$refs.code.focus();
        }
      },
      resetModal() {
        this.trigger.id = false;
        this.trigger.name = "";
        this.trigger.code = "";
      },
      fillData() {
        if (this.currentTrigger.id) {
          this.trigger = { ...this.currentTrigger };
        } else {
          this.resetModal();
        }
      },
    },
    components: {},
    computed: {
      ...mapGetters({
        currentTrigger: "currentTrigger",
        currentTemplateId: "currentTemplateId",
      }),
      showModal: {
        get() {
          return this.$store.state.triggers.triggerModalVisible;
        },
        set(value) {
          this.$store.commit(types.SET_TRIGGER_MODAL, value);
        },
      },
    },
    watch: {
      
    },
    created() {},
  };
</script>
