import * as types from "../types";
import Swal from "sweetalert2";

export const getTemplates = ({ commit }) => {
    axios
        .get("getTemplates")
        .then((response) => {
            // console.log('templates', response.data)
            commit(types.SET_TEMPLATES, response.data.data);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getCurrentTemplateData = ({ commit }, id) => {
    axios
        .get("getCurrentTemplateData", {params:{id}})
        .then((response) => {
            console.log('getCurrentTemplateData', response.data)
            commit(types.SET_CURRENT_TEMPLATE_DATA, response.data.data);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getCurrentTemplateDataWithDescription = ({ commit }, id) => {
    console.log("getCurrentTemplateDataWithDescription", id)
    axios
        .get("getCurrentTemplateDataWithDescription", {params:{id}})
        .then((response) => {
            console.log('getCurrentTemplateDataWithDescription', response.data)
            commit(types.SET_CURRENT_TEMPLATE_DATA, response.data.data);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const handleTemplate = ({ commit, dispatch }, data) => {
    axios
        .post("handleTemplate", data)
        .then((response) => {
            // console.log("handleTemplate", response.data);
            if (!!response.data.result) {
                dispatch("getTemplates");

                if(response.data.request.id) {
                    dispatch("getCurrentTemplateDataWithDescription", {id: response.data.request.id, withDescription: true});
                }
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const deleteTemplate = ({ commit, dispatch }, id) => {
    axios
        .delete("deleteTemplate", {
            params: { id },
        })
        .then((response) => {
            console.log("deleteTemplate", response.data);
            if (response.data.result) {
                commit(types.SET_CURRENT_TEMPLATE, {});
                commit(types.SET_CURRENT_TEMPLATE_DATA, {});
                dispatch("getTemplates");
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};
