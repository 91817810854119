import * as types from '../types';
import Swal from 'sweetalert2'

export const handleTrigger = ({ commit, state, dispatch }, data) => {
    axios
        .post("handleTrigger", data)
        .then((response) => {
            console.log("handleTrigger", response.data);
            if (!!response.data.result) {
                dispatch("getCurrentTemplateData", state.templates.currentTemplateId);
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const deleteTrigger = ({ commit, dispatch, state }, id) => {
    axios
        .delete("deleteTrigger", {
            params: { id },
        })
        .then((response) => {
            console.log("deleteTrigger", response.data);
            if (response.data.result) {
                dispatch("getCurrentTemplateData", state.templates.currentTemplateId);
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};