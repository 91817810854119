require("./bootstrap");

import Alpine from "alpinejs";
import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import store from "./store/store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { SET_ADMIN_ID } from "./store/types";
import messages from "../assets/lang/uk.json";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createI18n } from "vue-i18n";
import "vue-universal-modal/dist/index.css";
import VueUniversalModal from "vue-universal-modal";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import BootstrapVue3 from "bootstrap-vue-3";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import ConfirmationService from "primevue/confirmationservice";
// import { QuillEditor } from '@vueup/vue-quill';
// import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import { CarouselPlugin } from 'bootstrap-vue'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import "./icons";
import moment from "moment";

window.Alpine = Alpine;
let default_locale = "uk";
let fallback_locale = "uk";

Alpine.start();

const Vue = createApp({});

const i18n = createI18n({
    locale: default_locale,
    fallbackLocale: fallback_locale,
    messages,
});

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.MIX_APP_API_URL;
Vue.axios.defaults.withCredentials = true;
Vue.axios.defaults.headers[
    "Authorization"
] = `Bearer ${process.env.MIX_APP_API_TOKEN}`;

Vue.config.globalProperties.$filters = {
    date(value) {
        return moment(value).format("DD.MM.YYYY");
    },
    dbDate(value) {
        return moment(value).format("YYYY-MM-DD");
    },
};

const axiosInstance = axios.create({
    baseURL: process.env.MIX_APP_API_URL,
    withCredentials: true,
    params: {
        admin_id: +sessionStorage.getItem("admin_id"),
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = process.env.MIX_APP_API_TOKEN;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

Vue.config.globalProperties.$axios = axiosInstance;

const router = createRouter({
    history: createWebHistory(),
    routes,
});

if (sessionStorage.getItem("admin_id")) {
    Vue.axios.defaults.params = {};
    Vue.axios.defaults.params["admin_id"] = parseInt(
        sessionStorage.getItem("admin_id")
    );

    store.commit(SET_ADMIN_ID, +sessionStorage.getItem("admin_id"));

    setTimeout(() => {
        store.dispatch("getAdminData");
    }, 100);
}


Vue.use(router)
    .use(store)
    .use(i18n)
    .use(vSelect)
    // .use(BootstrapVue)
    .use(BootstrapVue3)
    .use(PrimeVue)
    .use(ConfirmationService)
    .use(VueUniversalModal, {
        teleportTarget: "#modals",
    })
    .use(VueSweetalert2)
    .provide("axios", Vue.config.globalProperties.$axios)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("v-select", vSelect)
    .component("Dialog", Dialog)
    // .component('b-carousel', BCarousel)
    // .component('b-carousel-slide', BCarouselSlide)
    // .component('QuillEditor', QuillEditor)
    .mount("#app");
