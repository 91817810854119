import * as types from '../types';

const state = {
  adminId: null,
  adminData: [],
  admins: [],
  overlayVisible: false
};

const mutations = {
  [types.SET_ADMIN_ID]: (state, id) => {
    state.adminId = id
  },
  [types.SET_ADMIN_DATA]: (state, admin) => {
    state.adminData = admin
  },
  [types.SET_ADMINS]: (state, admins) => {
    state.admins = admins
  },
  [types.SET_OVERLAY_VISIBLE]: (state, status) => {
    state.overlayVisible = status
  },
};

const getters = {
  adminId(state, getters) {
    return state.adminId
  },
  adminData(state, getters) {
    return state.adminData
  },
  admins(state, getters) {
    return state.admins
  },
  overlayVisible(state, getters) {
    return state.overlayVisible
  },
};

export default {
  state,
  mutations,
  getters
}