import Templates from "./components/templates/Templates.vue";
import StepData from "./components/steps/StepData.vue";

export const routes = [
    {
        path: "/templates",
        component: Templates,
        children: [],
    },
    {
        path: "/step/:link",
        component: StepData,
        children: [],
    },
];
