import * as types from '../types';
import Swal from 'sweetalert2'

export const getAllAdmins = ({ commit }) => {
    axios.get('getAllAdmins')
        .then(response => {
            commit(types.SET_ADMINS, response.data.data)
        }).catch(response => {
            Swal.fire('Error');
        })
};

export const getAdminData = ({ commit, state, dispatch}) => {
    axios.get('getAdminData')
    .then(response => {
    //   console.log("getAdminData", response.data)
      commit(types.SET_ADMIN_DATA, response.data.data)
    }).catch(response => {
        Swal.fire('Error');
    })
  };
  