import * as types from "../types";
import Swal from "sweetalert2";

export const handleStep = ({ commit, state, dispatch }, data) => {
    axios
        .post("handleStep", data)
        .then((response) => {
            console.log("handleStep", response.data);
            if (!!response.data.result) {
                dispatch(
                    "getCurrentTemplateDataWithDescription",
                    state.templates.currentTemplateId
                );
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getMaxStepsPosition = ({ commit, state }) => {
    axios
        .get("getMaxStepsPosition", {
            params: { template_id: state.templates.currentTemplateId },
        })
        .then((response) => {
            console.log("getMaxStepsPosition", response.data);
            commit(types.SET_MAX_STEPS_POSITION, response.data.position);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getStepDescription = ({ commit, state }, { id, show = false }) => {
    axios
        .get("getStepDescription", {
            params: { id },
        })
        .then((response) => {
            console.log("getStepDescription", response.data);
            commit(types.SET_STEP_DESCRIPTION, {
                step_id: id,
                template_id: state.templates.currentTemplateId,
                description: response.data.data.description,
            });

            if (show) {
                commit(types.SET_STEP_MODAL, true);
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getSteps = ({ commit, state }) => {
    axios
        .get("getSteps", {
            params: { template_id: state.templates.currentTemplateId },
        })
        .then((response) => {
            console.log("getSteps", response.data);
            commit(types.SET_STEPS, response.data.data);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getMaxStepsNumber = ({ commit, state }) => {
    axios
        .get("getMaxStepsNumber", {
            params: { template_id: state.templates.currentTemplateId },
        })
        .then((response) => {
            console.log("getMaxStepsNumber", response.data);

            commit(types.SET_MAX_STEPS_NUMBER, response.data.number);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const getStepData = ({ commit, state }, link) => {
    axios
        .get("getStepData", {
            params: { link },
        })
        .then((response) => {
            console.log("getStepData", response.data);

            commit(types.SET_STEP_DATA, response.data);
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};

export const updateStepPosition = ({ dispatch, state }, data) => {
    axios
        .put("updateStepPosition", {
            ...data,
            template_id: state.templates.currentTemplateId,
        })
        .then((response) => {
            console.log("updateStepPosition", response.data);
            // dispatch(
            //     "getCurrentTemplateData",
            //     state.templates.currentTemplateId
            // );
            dispatch("getSteps");
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        });
};

export const deleteStep = ({ commit, dispatch, state }, id) => {
    axios
        .delete("deleteStep", {
            params: { id },
        })
        .then((response) => {
            console.log("deleteStep", response.data);
            if (response.data.result) {
                // dispatch(
                //     "getCurrentTemplateData",
                //     state.templates.currentTemplateId
                // );
                dispatch("getSteps");
            }
        })
        .catch((error) => {
            Swal.fire("Error", error.message);
        })
        .finally(() => {
            commit(types.SET_OVERLAY_VISIBLE, false);
        });
};
