import * as types from "../types";

const state = {
    templates: [],
    currentTemplate: {},
    currentTemplateData: {},
    currentTemplateId: false,
    templateModalVisible: false,
    stepKey: 0
};

const mutations = {
    [types.SET_TEMPLATES]: (state, data) => {
        state.templates = data;
    },
    [types.SET_TEMPLATE_MODAL]: (state, status) => {
        state.templateModalVisible = status;
    },
    [types.SET_CURRENT_TEMPLATE]: (state, data) => {
        state.currentTemplate = data;
        state.currentTemplateId = data.id;
    },
    [types.SET_STEP_DESCRIPTION]: (state, data) => {
        // console.log('SET_STEP_DESCRIPTION', state.currentTemplateData.steps, data)
        const steps = state.currentTemplateData.steps.map(step => {
            if(step.id === data.step_id && state.currentTemplateData.id === data.template_id) {
                // console.log("sfsafa", data.description)
                step.description = data.description
            }
            return step
        })
        state.currentTemplateData = {...state.currentTemplateData, steps}
        state.stepKey = Math.random()
    },
    [types.SET_STEPS]: (state, data) => {
        // console.log('SET_STEPS', state.currentTemplateData.steps, data)
        state.currentTemplateData = {...state.currentTemplateData, steps: data}
    },
    [types.SET_CURRENT_TEMPLATE_DATA]: (state, data) => {
        state.currentTemplateData = data;
    },
};

const getters = {
    currentTemplate(state) {
        return state.currentTemplate;
    },
    currentTemplateId(state) {
        return state.currentTemplateId;
    },
    templates(state) {
        return state.templates;
    },
    templateModalVisible(state) {
        return state.templateModalVisible;
    },
    currentTemplateData(state) {
        return state.currentTemplateData;
    },
    stepKey(state) {
        return state.stepKey;
    },
};

export default {
    state,
    mutations,
    getters,
};
