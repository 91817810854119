<template>
  <Modal
    v-model="showModal"
    :close="closeModal"
    @before-enter="fillData"
    @after-enter="focus"
    @after-leave="resetMaxPosition"
  >
    <div
      class="lgModal"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <a href="#" class="closeModalButton" @click.prevent="closeModal">
            <font-awesome-icon icon="times" />
          </a>
          <p class="h5">{{ $t("steps.step") }}</p>

          <div class="d-block">
            <div class="mb-2 form-group" :label="$t('steps.number')">
              <label for="step" class="form-label text-start d-block">
                {{ $t("steps.number") }}
              </label>
              <input
                id="position"
                autocapitalize="sentences"
                class="form-control"
                v-model="step.position"
                type="text"
                ref="position"
              />
            </div>

            <div class="mb-2 form-group" :label="$t('steps.name')">
              <label for="name" class="form-label text-start d-block">
                {{ $t("steps.name") }}
              </label>
              <textarea
                autofocus
                id="name"
                class="form-control"
                v-model="step.name"
                ref="name"
                rows="3"
              />
            </div>

            <!-- <div class="mb-2 form-group" :label="$t('steps.link')">
              <label for="link" class="form-label text-start d-block">
                {{ $t("steps.link") }}
              </label>
              <input
                id="link"
                autofocus
                autocapitalize="sentences"
                class="form-control"
                v-model="step.link"
                type="text"
                ref="link"
              />
            </div> -->

            <div class="form-group mb-3">
              <label for="description" class="form-label d-block text-start">
                {{ $t("steps.description") }}
              </label>
              <Editor
                id="description"
                api-key="ms93trbrrx5jjiut70q2cv8uavbducnpg85wfo61uyqazg7f"
                :init="tiny"
                v-model="step.description"
              />
              <!-- <textarea
                id="description"
                
              /> -->
            </div>
          </div>

          <button
            :disabled="!(step.name && step.number && step.description)"
            class="btn btn-outline-success mt-3"
            @click="handleAction"
          >
            {{ $t(step.id ? "all.edit" : "all.add") }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";
  import Editor from "@tinymce/tinymce-vue";

  export default {
    data() {
      return {
        step: {
          id: false,
          name: "",
          number: 1,
          link: "",
          description: "",
          position: 1,
        },
        tiny: {
          selector: "textarea#description",
          plugins:
            "anchor autolink charmap codesample image link lists media searchreplace table visualblocks wordcount",
          toolbar:
            "undo redo | blocks fontfamily fontsize | forecolor backcolor | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | removeformat actionButton resultButton",
          setup: (editor) => {
            editor.ui.registry.addButton("actionButton", {
              text: "Action",
              // icon: 'insert-time',
              onAction: (_) => {
                const content = editor.selection.getContent({ format: "text" });
                // console.log(editor.getContent({ format: 'text' }))
                editor.insertContent(
                  `<p style="color: blue; font-weight: bold">${content}</p>`
                );
              },
            });

            editor.ui.registry.addButton("resultButton", {
              text: "Result",
              // icon: 'insert-time',
              onAction: (_) => {
                const content = editor.selection.getContent({ format: "text" });

                // console.log('content', content, $(content).find('ul'), $(content).find('ul').addClass('bold-brown'),  $(content).find('ul').addClass('bold-brown').prop('outerHTML'))
                // console.log('content', $(content).find('ol').addClass('bold-brown').clone().html())

                editor.insertContent(
                  `<p style="color: brown; font-weight: bold">${content}</p>`
                );
                // editor.insertContent($(content).addClass('bold-brown'))
              },
            });
          },

          // tinycomments_mode: 'embedded',
          // tinycomments_author: 'Author name',
          images_file_types: "jpg,jpeg,png,gif",
          a11y_advanced_options: true,
          // language: "uk",
          image_class_list: [
            { title: "None", value: "" },
            { title: "Inline", value: "d-inline" },
            { title: "Block", value: "d-block" },
          ],
          table_class_list: [
            { title: "None", value: "" },
            { title: "Default", value: "table" },
            { title: "Compact", value: "table table-sm" },
          ],
          automatic_uploads: true,
          images_upload_url: process.env.MIX_APP_API_URL + "handleMedia",
        },
      };
    },

    methods: {
      handleAction() {
        if (this.step.name && this.step.number && this.step.description) {
          this.$store.commit(types.SET_OVERLAY_VISIBLE, true);
          this.$store.dispatch("handleStep", {
            ...this.step,
            template_id: this.currentTemplateId,
          });
          this.closeModal();
        }
      },
      resetMaxPosition() {
        this.$store.commit(types.SET_MAX_STEPS_POSITION, 0);
        this.$store.commit(types.SET_MAX_STEPS_NUMBER, 0);
      },
      closeModal() {
        this.$store.commit(types.SET_STEP_MODAL, false);
        this.resetModal();
      },
      focus() {
        if (this.$refs.name) {
          this.$refs.name.focus();
        }
      },
      resetModal() {
        this.step.id = false;
        this.step.name = "";
        this.step.number = "";
        this.step.link = "";
        this.step.description = "";
        this.step.position = 1;
      },
      fillData() {
        if (this.currentStep.id) {
          this.step = { ...this.currentStep };
        } else {
          this.resetModal();
        }
      },
    },
    components: { Editor },
    computed: {
      ...mapGetters({
        currentStep: "currentStep",
        currentStepId: "currentStepId",
        currentTemplateId: "currentTemplateId",
        maxStepsPosition: "maxStepsPosition",
        maxStepsNumber: "maxStepsNumber",
      }),
      showModal: {
        get() {
          return this.$store.state.steps.stepModalVisible;
        },
        set(value) {
          this.$store.commit(types.SET_STEP_MODAL, value);
        },
      },
    },
    watch: {
      maxStepsPosition(newValue, oldValue) {
        if (!this.currentStep.id) {
          this.step.position = newValue;
        }
      },
      maxStepsNumber(newValue, oldValue) {
        if (!this.currentStep.id) {
          this.step.number = newValue;
        }
      },
    },
    created() {},
    mounted() {},
  };
</script>
<style scoped>
</style>