<template>
  <div class="overlay" v-if="overlayVisible">
    <div class="inner">
      <div class="spinner-grow text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary ms-3 me-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        overlayVisible: "overlayVisible",
      }),
    },
  };
</script>

<style scoped>
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.232);
    z-index: 10000;
  }
  .inner {
    flex: 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
</style>