<template>
  <div
    :class="[activeTrigger, 'mb-1 on_hover card col-6']"
  >
    <div class="card-header d-flex justify-content-between align-items-center ">
      <span>
        {{ trigger.code }}
      </span>
      <span>
        {{ trigger.name }}
      </span>
      <button
        type="button"
        class="btn btn-light dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click.prevent=""
      >
        <font-awesome-icon icon="cog" />
      </button>
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item" href="#" @click="updateTrigger">{{
            $t("all.edit")
          }}</a>
        </li>
        <li>
          <a class="dropdown-item text-danger" @click="deleteDialog" href="#">{{
            $t("all.delete")
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";

  export default {
    data() {
      return {};
    },
    props: ["trigger"],
    computed: {
      ...mapGetters({
        currentTrigger: "currentTrigger",
        currentTriggerId: "currentTriggerId",
      }),
      activeTrigger() {
        return this.trigger.id == this.currentTriggerId ? "border-success" : null;
      },
    },
    methods: {
      deleteDialog() {
        const store = this.$store;
        const id = this.trigger.id;

        console.log("id", id)

        this.$confirm.require({
          message: "Бажаєте видалити?",
          header: "Підтвердження",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Так",
          rejectLabel: "Ні",
          accept: () => {
            store.commit(types.SET_OVERLAY_VISIBLE, true);
            store.dispatch("deleteTrigger", id);
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
          onHide: () => {
            //Callback to execute when dialog is hidden
          },
        });
      },
      updateTrigger() {
        this.$store.commit(types.SET_CURRENT_TRIGGER, this.trigger);
        this.$store.commit(types.SET_TRIGGER_MODAL, true);
      },
    },
    components: {},
  };
</script>