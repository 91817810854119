export const SET_ADMINS = "SET_ADMINS";
export const SET_ADMIN_DATA = "SET_ADMIN_DATA";
export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const SET_OVERLAY_VISIBLE = "SET_OVERLAY_VISIBLE";

// TEMPLATES
export const SET_CURRENT_TEMPLATE = "SET_CURRENT_TEMPLATE";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_TEMPLATE_MODAL = "SET_TEMPLATE_MODAL";
export const UPDATE_TEMPLATES = "UPDATE_TEMPLATES";
export const SET_CURRENT_TEMPLATE_DATA = "SET_CURRENT_TEMPLATE_DATA";

// TRIGGERS
export const SET_CURRENT_TRIGGER = "SET_CURRENT_TRIGGER";
export const SET_TRIGGER_MODAL = "SET_TRIGGER_MODAL";

// STEPS
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_STEP_MODAL = "SET_STEP_MODAL";
export const SET_MAX_STEPS_POSITION = "SET_MAX_STEPS_POSITION";
export const SET_MAX_STEPS_NUMBER = "SET_MAX_STEPS_NUMBER";
export const SET_STEP_DATA = "SET_STEP_DATA";
export const SET_IMAGES_MODAL = "SET_IMAGES_MODAL";
export const SET_CAROUSEL_IMAGES = "SET_CAROUSEL_IMAGES";
export const SET_IMAGES_INDEX = "SET_IMAGES_INDEX";
export const SET_STEP_DESCRIPTION = "SET_STEP_DESCRIPTION";
export const SET_STEPS = "SET_STEPS";
