<template>
    <Modal
      v-model="showModal"
      :close="closeModal"
      @before-enter="fillData"
      @after-enter="focus"
    >

        <div
            class="fullModal"
            id="imagesModal"
        >
        <a href="#" class="closeModalButtonCarousel" @click.prevent="closeModal">
            <font-awesome-icon icon="times" />
          </a>
            <carousel items-to-show="1" :modelValue="imagesIndex">
                <slide v-for="(image, i) in images" :key="i">
                    <b-img :src="image"/>
                </slide>

                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
    </Modal>
  </template>
  
  <script type="text/babel">
    import { mapGetters } from "vuex";
    import * as types from "../../store/types";
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

    export default {
      data() {
        return {
            slide: 0,
        };
      },
      methods: {
       
        closeModal() {
          this.$store.commit(types.SET_IMAGES_MODAL, false);
          this.$store.commit(types.SET_IMAGES_INDEX, 0);
          this.$store.commit(types.SET_CAROUSEL_IMAGES, [])
          this.resetModal();
        },
        focus() {
         
        },
        resetModal() {
          
        },
        fillData() {
          this.slide = this.imagesIndex
        },
      },
      components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
      },
      computed: {
        ...mapGetters({
          images: "images",
          imagesIndex: "imagesIndex"
        }),
        showModal: {
          get() {
            return this.$store.state.steps.imagesModalVisible;
          },
          set(value) {
            this.$store.commit(types.SET_IMAGES_MODAL, value);
          },
        },
      },
      watch: {
        
      },
      created() {},
    };
  </script>
  