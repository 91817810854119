import * as types from "../types";

const state = {
    currentTrigger: {},
    currentTriggerId: false,
    triggerModalVisible: false,
};

const mutations = {
    [types.SET_TRIGGER_MODAL]: (state, status) => {
        state.triggerModalVisible = status;
    },
    [types.SET_CURRENT_TRIGGER]: (state, data) => {
        state.currentTrigger = data;
        state.currentTriggerId = data.id;
    },
};

const getters = {
    currentTrigger(state) {
        return state.currentTrigger;
    },
    currentTriggerId(state) {
        return state.currentTriggerId;
    },
    triggerModalVisible(state) {
        return state.triggerModalVisible;
    },
};

export default {
    state,
    mutations,
    getters,
};
