import { createStore } from "vuex";
import * as action from "./actions";
import templates from "./modules/templates";
import admins from "./modules/admins";
import triggers from "./modules/triggers";
import steps from "./modules/steps";

const store = createStore({
    actions: action,

    modules: {
        admins,
        templates,
        triggers,
        steps
    },
});

export default store;
