<template>
  <div>
    <TemplateModal />
    <Overlay />
    <ConfirmDialog />

    <div class="row">
      <div class="col-3">
        <div class="d-flex mb-2">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="$t('templates.add_new_item')"
            class="btn btn-sm btn-outline-info me-2 pe-4 ps-4"
            type="button"
            @click="addNewItem"
          >
            <font-awesome-icon icon="plus" />
          </button>

          <button
            v-if="!noInactiveTemplates"
            class="btn btn-sm btn-outline-secondary me-2"
            type="button"
            style="width: 55px"
            @click="toggleEye"
          >
            <font-awesome-icon :icon="isEyeOpen ? 'eye' : 'eye-slash'" />
          </button>
          <div class="input-group input-group-sm">
            <input
              type="text"
              class="form-control"
              ref="searchTemplatesField"
              v-model="search"
              :disabled="!templates.length"
              :placeholder="$t('templates.enter_template_name')"
              aria-describedby="basic-addon2"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="resetSearch"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>

        <div class="clinicsContainer" :style="{ height: `${scrollHeight}px` }">
          <draggable
            v-model="templates"
            @start="drag = true"
            @end="drag = false"
            item-key="id"
            @change="sortItems"
          >
            <template #item="{ element }">
              <TemplateItem
                v-if="
                  search.length == 0 ||
                  element.name
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
                "
                :template="element"
              />
            </template>
          </draggable>
        </div>
      </div>

      <div class="col-9">
        <template v-if="currentTemplate.id">
          <TemplateData />
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import TemplateItem from "./Template.vue";
  import TemplateModal from "./TemplateModal.vue";
  import TemplateData from "./TemplateData.vue";
  import ConfirmDialog from "primevue/confirmdialog";

  import * as bootstrap from "bootstrap";
  import * as types from "../../store/types";
  import draggable from "vuedraggable";
  import Overlay from "../Overlay.vue";

  export default {
    data() {
      return {
        drag: false,
        isEyeOpen: false,
        search: "",
        height: window.innerHeight,
        scrollHeight: window.innerHeight - 160,
      };
    },
    components: {
      TemplateItem,
      TemplateModal,
      TemplateData,
      draggable,
      Overlay,
      ConfirmDialog,
    },
    computed: {
      ...mapGetters({
        templateModalVisible: "templateModalVisible",
        currentTemplate: "currentTemplate",
      }),
      templates: {
        get() {
          return this.$store.state.templates.templates.filter((m) =>
            this.isEyeOpen ? true : m.active
          );
        },
        set(value) {
          this.$store.commit(types.UPDATE_TEMPLATES, value);
        },
      },
      noInactiveTemplates: {
        get() {
          return this.$store.state.templates.templates.every((m) => m.active);
        },
      },
    },
    methods: {
      toggleEye() {
        this.isEyeOpen = !this.isEyeOpen;
      },
      addNewItem() {
        this.$store.commit(types.SET_CURRENT_TEMPLATE, {});
        this.$store.commit(types.SET_TEMPLATE_MODAL, true);
      },
      sortItems({ moved: { element, oldIndex, newIndex } }) {
        // this.$store.dispatch("updateModulePosition", {
        //   id: element.id,
        //   oldIndex,
        //   newIndex,
        // });
      },
      resetSearch() {
        this.search = "";
        if (
          this.$refs.searchTemplatesField &&
          this.$refs.searchTemplatesField != null
        ) {
          this.$refs.searchTemplatesField.focus();
        }
      },
    },
    created() {
      this.$store.dispatch("getTemplates");
    },
    mounted() {
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {
          delay: { show: 1000, hide: 50 },
        });
      });
    },
  };
</script>
<style>
</style>