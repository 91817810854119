import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,
    faEdit,
    faPlus,
    faCog,
    faLightbulb,
    faEye,
    faEyeSlash,
    faLineChart,
    faComment,
    faEnvelopeOpenText,
    faCheck,
    faChevronUp,
    faChevronDown,
    faLink,
} from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);
library.add(faEdit);
library.add(faPlus);
library.add(faCog);
library.add(faEye);
library.add(faEyeSlash);
library.add(faLightbulb);
library.add(faLineChart);
library.add(faComment);
library.add(faEnvelopeOpenText);
library.add(faCheck);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faLink);
