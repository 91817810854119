<template>
  <Modal
    v-model="showModal"
    :close="closeModal"
    @before-enter="fillData"
    @after-enter="focus"
  >
    <div
      class="smModal"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <a href="#" class="closeModalButton" @click.prevent="closeModal">
            <font-awesome-icon icon="times" />
          </a>
          <p class="h5">{{ $t("templates.template") }}</p>

          <div class="d-block">
            <div class="mb-2 form-group" :label="$t('all.name')">
              <label for="name" class="form-label text-start d-block">
                {{ $t("all.name") }}
              </label>
              <input
                id="name"
                autofocus
                autocapitalize="sentences"
                class="form-control"
                v-model="template.name"
                type="text"
                ref="template"
                @keyup.enter="handleAction"
              />
            </div>

            <div class="form-group mb-3">
              <label
                for="number"
                class="form-label d-block text-start"
              >
                {{ $t("all.number") }}
              </label>
              <input
                id="number"
                class="form-control"
                v-model="template.number"
                type="number"
              />
            </div>
            <div class="form-group mb-3">
              <label
                for="version"
                class="form-label d-block text-start"
              >
                {{ $t("templates.version") }}
              </label>
              <input
                id="version"
                class="form-control"
                v-model="template.version"
                type="number"
              />
            </div>
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="template.active"
              id="active_template"
              ref="active_template"
            />
            <label
              class="form-check-label d-flex ms-2"
              style="text-align: left; padding-top: 1px"
              for="active_template"
              >{{ $t("templates.active") }}</label
            >
          </div>
          <button
            :disabled="!(template.name && template.number)"
            class="btn btn-outline-success mt-3"
            @click="handleAction"
          >
            {{ $t(template.id ? "all.edit" : "all.add") }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script type="text/babel">
  import { mapGetters } from "vuex";
  import * as types from "../../store/types";

  export default {
    data() {
      return {
        template: {
          id: false,
          name: "",
          number: "",
          version: "",
          active: true,
        },
      };
    },
    methods: {
      handleAction() {
        if (this.template.name && this.template.number && this.template.version) {
          this.$store.commit(types.SET_OVERLAY_VISIBLE, true);
          this.$store.dispatch("handleTemplate", this.template);
          this.closeModal();
        }
      },
      
      closeModal() {
        this.$store.commit(types.SET_TEMPLATE_MODAL, false);
        this.resetModal();
      },
      focus() {
        if (this.$refs.template) {
          this.$refs.template.focus();
        }
      },
      resetModal() {
        this.template.id = false;
        this.template.name = "";
        this.template.number = "";
        this.template.version = "";
        this.template.active = true;
      },
      fillData() {
        if (this.currentTemplate.id) {
          this.template = { ...this.currentTemplate };

          this.template.active = !!this.currentTemplate.active;

          setTimeout(() => {
            if (this.$refs.active_template) {
              this.$refs.active_template.checked = !!this.currentTemplate.active;
            }
          }, 250);
        } else {
          this.resetModal();
        }
      },
    },
    components: {},
    computed: {
      ...mapGetters({
        currentTemplate: "currentTemplate",
      }),
      showModal: {
        get() {
          return this.$store.state.templates.templateModalVisible;
        },
        set(value) {
          this.$store.commit(types.SET_TEMPLATE_MODAL, value);
        },
      },
    },
    watch: {
      
    },
    created() {},
  };
</script>
